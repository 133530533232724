.imgWidth {
    height: 80px;
}

.MuiListItemText-primary{
    font-weight: 500 !important;
}

@media only screen and (max-width: 1024px) {
    .imgWidth {
        height: 60px;        
    }
}