body {
    background-color: #d5e1ea !important;
}

.sectionStyle {
    padding            : 150px 0px;
    background-size    : cover;
    background-position: center 70%;

    .divWidth {
        width : 80%;
        margin: auto;
    }

    .divBgStyle {
        padding         : 30px 50px 50px 50px;
        background-color: white;
        box-shadow      : 1px 1px 8px 0px #00000061;
    }

    .fontStyle {
        text-decoration: none;
        font-weight    : 700;
        color          : #d3489a;
        text-transform : uppercase;
        font-weight    : 500;
    }

    .blackColor {
        color: #454243;
    }
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #454243 !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.package li {
    border-bottom: 1px solid #d3d3d3 !important;
}

.image-gallery-slide .image-gallery-description {
    background-color: #b87333;
}

.roomSlider {
    .slick-prev {
        left: -90px;

        &:before {
            color: #454243;
        }
    }

    .slick-next {
        right: -90px;

        &:before {
            color: #454243;
        }
    }
}

@media only screen and (max-width: 768px) {
    .sectionStyle {
        .divWidth {
            width: 80%
        }
    }

    body .accommodation {
        font-size: 16px;
    }

    body .divBgStyle {
        padding: 10px !important;
    }

    h2 {
        font-size  : 1.25rem;
        line-height: 1.5em;
    }

    .roomSlider {
        .slick-prev {
            left: -40px;
        }

        .slick-next {
            right: -40px;
        }
    }

    .accommodation-text {
        font-size  : 0.8rem;
        line-height: 1.5em;
    }

    .roomSliderImage {
        width: 100%;
    }
}

.MuiFormLabel-root.Mui-focused {
    color: #454243 !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #454243 !important;
}

.comingSoonTitle {
    font-size  : 4rem;
    font-weight: 400;
    color      : #101d39;
}


.videoHomepage {
    width          : 100% !important;
    height         : auto !important;
    position       : fixed;
    top            : 100px;
    left           : 0;
    min-width      : 100%;
    min-height     : 100%;
    background-size: cover;
    z-index        : -10;
}

.divPosition {
    position       : absolute;
    left           : 0;
    top            : 0;
    display        : flex;
    height         : 100%;
    width          : 100%;
    align-items    : flex-end;
    justify-content: center;
}

.contentDiv {
    background-color: #d5e1ea8c;
    padding         : 10px;
    text-align      : center;
    width           : 100%;
}

.logoImage {
    width: 60%;
}

.visitStoreButton {
    margin-top: 10px !important;
    background: #0e1936 !important;
    font-size : 1rem !important;
    color     : #fff !important;

    .fa {
        margin-left: 15px;
    }
}

.btn-subscribe {
    font-size       : 1.2rem;
    background-color: #febe11;
    border          : none;
    color           : white;
    padding         : 10px;
    cursor          : pointer;
}

.newsLetter {
    margin-top   : 0;
    color        : #0e1936;
    font-weight  : 700;
    font-size    : 1.1rem;
    margin-bottom: 20px;
}

.linkForStore {
    margin-top   : 0;
    color        : #0e1936;
    font-weight  : 700;
    font-size    : 1.1rem;
    margin-bottom: 5px;
}

.newsletter__form {

    form {
        margin-bottom: 5px;
    }

    input {
        padding     : 9px;
        font-size   : 1.2rem;
        border-color: #febe11;
        border-style: solid;
        border-width: 1px;
        border-image: none;
    }

    p {
        font-size  : 16px;
        font-weight: 500;
    }
}


.socialMedia {
    color      : #0e1936;
    font-weight: 700;
    font-size  : 1.1rem;

    a {
        color: inherit;
    }

    i {
        margin-right: 15px;
    }
}

.socialMediaIcon {
    margin-top: 20px;
}

.buttonDiv {
    height     : 100%;
    display    : flex;
    align-items: center;
}

.paddingContainer {
    padding-top: 10px;
}

.webFooter {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

@media only screen and (max-width: 768px) {
    .comingSoonTitle {
        font-size: 2.4rem;
    }

    .videoHomepage {
        position  : unset;
        min-width : 0;
        min-height: 0;
        margin-top: 80px;
    }

    .logoImage {
        width: 80%;
    }

    .visitStoreButton {
        font-size: 0.8rem !important;
    }

    .btn-subscribe {
        font-size: 0.8rem;
    }

    .newsletter__form input {
        font-size: 0.8rem;
    }

    .mobileContentDiv {
        margin-top: 10px;
        text-align: center;
    }

    .socialMediaContainer {
        margin-top   : 0px;
        margin-bottom: 0px;

        .socialMedia {
            font-size: 1.2rem;
        }
    }


    .buttonDiv {
        align-items    : flex-start;
        justify-content: center;
        margin-bottom  : 20px;
    }

    .mobileDivContent {
        margin-bottom: 30px !important;
    }

}

.mobileContent {

    height     : 50vh;
    display    : flex;
    align-items: center;
}

.customGrid {
    width : 100% !important;
    margin: 0 !important
}

// Landscape IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .videoHomepage {
        position: fixed !important;
        top     : 0
    }

    .mobileContent {
        display: none;
    }
}

// Landscape IPAD pro
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .videoHomepage {
        position: unset ;
        top     : 0
    }
    .mobileContent {
        display: none;
    }
}

//potrait IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    .ipadHidden {
        display: none;
    }

    .videoHomepage {
        position: unset;
        margin-top: 100px;
    }

    .mobileDivContent {
        margin-bottom: 30px !important;
    }
}


//potrait IPAD Pro
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

    .ipadHidden {
        display: none;
    }

    .videoHomepage {
        position: unset;
        margin-top: 100px;
    }

    .mobileDivContent {
        margin-bottom: 30px !important;
    }
}